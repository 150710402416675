.admin-cards {
  padding-top: 40px;
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
  border-top: 1px solid #cfcfcf;
}

.admin-card {
  width: 200px;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(136, 136, 136, 0.3);
  transition: all .3s;
}

.admin-card:hover {
  box-shadow: 0 0 20px rgba(136, 136, 136, 0.3);
  transform: scale(1.05);
}

.admin-card-title {
  line-height: 2;
  margin: 0;
  font-weight: normal;
}

.admin-card-content {
  font-size: 50px;
  font-weight: bold;
}